import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import DataDisplay from '../DataDisplay/DataDisplay'

interface Props {
  eventContent: unknown | null
  isLoading: boolean
  errorMessage?: unknown | null
}

const EventDataContainer: FC<Props> = ({
  eventContent,
  isLoading,
  errorMessage,
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'eventViewer',
  })

  const investigationError = {
    error: {
      description: errorMessage,
    },
  }

  const data =
    eventContent && typeof eventContent === 'object'
      ? eventContent
      : investigationError

  return (
    <DataDisplay title={t('eventContent')} data={data} isLoading={isLoading} />
  )
}

export default EventDataContainer
