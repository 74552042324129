import { Form } from '@ant-design/compatible'
import { FormComponentProps } from '@ant-design/compatible/lib/form'
import { Input, Row, Select } from 'antd'
import moment from 'moment-timezone'
import { PureComponent, ReactNode, FC } from 'react'
import { Translation } from 'react-i18next'
import { DatePicker } from '@signifyd/components'
import { FULFILLMENT_STATUS, SHIPPING_CARRIER } from '@signifyd/http'
import { toTitleCase } from '@signifyd/utils'
import { DATE_FORMAT } from 'core/constants'
import {
  getFormInputWrapper,
  InputWrapperProps,
} from 'core/utils/getFormInputWrapper'
import { DefaultOptionType } from 'antd/lib/select'

const fulfillmentStatusOptions: Array<DefaultOptionType> = Object.entries(
  FULFILLMENT_STATUS
).map(([status]) => ({
  key: status,
  label: toTitleCase(status),
  value: status,
}))

const shippingCarrierOptions: Array<DefaultOptionType> = Object.entries(
  SHIPPING_CARRIER
).map(([value, label]) => ({
  key: value,
  label,
  value,
}))

export interface Props extends FormComponentProps {
  setHasErrors: (hasErrors: boolean) => void
  setHasChanges: (hasChanges: boolean) => void
}

class CaseClaimsBannerFulfillmentForm extends PureComponent<Props> {
  FormInputWrapper: FC<InputWrapperProps>

  constructor(props: Props) {
    super(props)
    this.FormInputWrapper = getFormInputWrapper(
      this.props.form.getFieldDecorator
    )
  }

  render(): ReactNode {
    const { FormInputWrapper } = this
    const transPrefix = 'claimsBanner.fulfillment.form'

    return (
      <Translation>
        {(t) => (
          <Form layout="vertical">
            <Row>
              <FormInputWrapper
                span={16}
                inputKey="trackingNumbers"
                translationPrefix={transPrefix}
                required
              >
                <Input
                  placeholder={t(`${transPrefix}.trackingNumbers.placeholder`)}
                  data-test-id="trackingNumbers"
                />
              </FormInputWrapper>

              <FormInputWrapper
                span={16}
                inputKey="shippingCarrier"
                translationPrefix={transPrefix}
                required
              >
                <Select
                  placeholder={t(`${transPrefix}.shippingCarrier.placeholder`)}
                  data-test-id="shippingCarrier"
                  options={shippingCarrierOptions}
                />
              </FormInputWrapper>

              <FormInputWrapper
                span={16}
                inputKey="fulfillmentStatus"
                translationPrefix={transPrefix}
                required
              >
                <Select
                  placeholder={t(
                    `${transPrefix}.fulfillmentStatus.placeholder`
                  )}
                  data-test-id="fulfillmentStatus"
                  options={fulfillmentStatusOptions}
                />
              </FormInputWrapper>

              <FormInputWrapper
                span={16}
                inputKey="createdAt"
                translationPrefix={transPrefix}
                required
                type="object"
              >
                <DatePicker
                  format={DATE_FORMAT.date}
                  placeholder={t(`${transPrefix}.createdAt.placeholder`)}
                  disabledDate={
                    (current) => !!current && current > moment().endOf('day') // Prevent future dates being selected
                  }
                  data-test-id="createdAtDate"
                />
              </FormInputWrapper>
            </Row>
          </Form>
        )}
      </Translation>
    )
  }
}

export default Form.create<Props>({
  name: 'FulfillmentForm',
  onFieldsChange: (props, _fields, allFields) => {
    props.setHasErrors(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values(allFields).some(({ errors }: any) => !!errors?.length)
    )
    props.setHasChanges(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values(allFields).some(({ value }: any) => !!value)
    )
  },
})(CaseClaimsBannerFulfillmentForm)
