import { FilterState, SortState } from 'store/search'
import { encodeUrlHash } from '../urlEncoder'

export function stripDateAndEncode(
  searchTerm: string,
  filters: FilterState,
  sort: SortState
): string {
  const filter: Partial<FilterState> = {
    ...filters,
  }

  delete filter.normalizedPurchaseCreatedAt

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return encodeUrlHash<any>({
    searchTerm,
    filters: filter,
    sort,
  })
}
