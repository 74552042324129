import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStoreState } from 'store'
import { PageSpinner } from '@signifyd/components'
import { Event } from '@signifyd/http'
import useGetInvestigationAtEvent from './useGetComparisonEvents'
import DataDisplay from '../DataDisplay/DataDisplay'
import { convertToTimestamp } from '../event.utils'

interface Props {
  investigationId: number
  compareEvents: Array<Event>
}

const CompareEvents: FC<Props> = ({ investigationId, compareEvents }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'eventViewer' })
  const timeZone = useStoreState((state) => state.user.timeZone.name)

  const { data, isFetching } = useGetInvestigationAtEvent(
    investigationId,
    compareEvents
  )

  if (isFetching) {
    return <PageSpinner />
  }

  const [firstEvent, secondEvent] = compareEvents

  const investigation1 = data?.investigations[0]
  const investigation2 = data?.investigations[1]

  const investigation1Error = {
    error: {
      description: data?.errors[0],
    },
  }

  const investigation2Error = {
    error: {
      description: data?.errors[1],
    },
  }

  return (
    <>
      <DataDisplay
        title={t('investigationEvent', {
          eventName: firstEvent.name,
        })}
        subTitle={convertToTimestamp(firstEvent.processedAt, timeZone)}
        data={investigation1 || investigation1Error}
        isLoading={isFetching}
      />
      <DataDisplay
        title={t('investigationEvent', {
          eventName: secondEvent.name,
        })}
        subTitle={convertToTimestamp(secondEvent.processedAt, timeZone)}
        data={investigation2 || investigation2Error}
        isLoading={isFetching}
      />
    </>
  )
}

export default CompareEvents
