import axios, { Canceler } from 'axios'
import {
  getInvestigationSearch,
  SEARCH_FIELD,
  INV_SEARCH_VIEW,
  InvestigationInfo,
} from '@signifyd/http'
import invSearchQueryBuilder from 'core/utils/invSearchQueryBuilder'

/*
 * This is a singleton module instance for polling for version changes
 * to the investigation document over a fixed period.
 *
 * Idea is that it
 * - Polls continuously for a maximum of 10s
 * - Waits 2secs after a successful poll response before making the next
 * This is to reduce load on the BE during busy periods when responses take longer
 * This also means the total number of poll requests is not fixed
 * - Calls the onChangeHandler anytime a version change is detected
 * It's possible the version will change multiple times during the
 * 10s polling period, so the handler can be called multiple times.
 *
 * If this ever needs to be reusable, and/or more than one poller instance is needed
 * this can either be refactored to a singleton factory, OR better would be to rewrite
 * as a class
 */
type StartPollingArgs = Pick<
  InvestigationInfo,
  'version' | 'investigationId'
> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeHandler: any
}

const pollInterval = 2000
const maxPollDuration = 10000

let polling = false
let pollingDurationTimer: number | null = null
let nextPollTimer: number | null = null
let cancelRequest: Canceler | null = null
let currentVersion: number | null = null
let id: number | null = null
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let onVersionChange: any | null = null

const poll = (): void => {
  const searchQuery = invSearchQueryBuilder({
    filters: { [SEARCH_FIELD.investigationId]: id },
    view: INV_SEARCH_VIEW.INVESTIGATION_CORE,
  })

  const setCancelRequest = (c: Canceler): void => {
    cancelRequest = c
  }

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  getInvestigationSearch(searchQuery, setCancelRequest)
    .then(({ data: { investigations } }) => {
      const [{ version }] = investigations
      if (version !== currentVersion) {
        currentVersion = version

        if (onVersionChange) {
          onVersionChange()
        }
      }
    })
    .catch((error) => axios.isCancel(error))
    .then((canceled: boolean | void) => {
      cancelRequest = null

      if (polling && !canceled) {
        nextPollTimer = window.setTimeout(poll, pollInterval)
      }
    })
}

const stopPolling = (): void => {
  if (pollingDurationTimer) {
    clearTimeout(pollingDurationTimer)
  }
  if (nextPollTimer) {
    clearTimeout(nextPollTimer)
  }
  if (cancelRequest) {
    cancelRequest()
  }

  pollingDurationTimer = null
  nextPollTimer = null
  cancelRequest = null
  currentVersion = null
  onVersionChange = null
  id = null
  polling = false
}

const pollForChanges = ({
  version,
  investigationId,
  onChangeHandler,
}: StartPollingArgs): void => {
  if (polling) {
    stopPolling()
  }

  onVersionChange = onChangeHandler
  polling = true
  currentVersion = version
  id = investigationId
  pollingDurationTimer = window.setTimeout(stopPolling, maxPollDuration)
  poll()
}

export default { pollForChanges, stopPolling }
