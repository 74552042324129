import { CloseOutlined, SearchOutlined } from '@ant-design/icons'
import { Divider, Row, Col } from 'antd'
import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Space, Text, ErrorBoundary } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import getPlaceholderText from 'core/utils/getPlaceholderText'
import { SavedFilter } from 'store/search'
import styles from './SearchContainer.less'
import QuickViews from '../QuickViews'
import SavedFilters from '../SavedFilters'
import SearchSummary from '../SearchSummary'

interface Props {
  searchTerm: string
  editing: boolean
  onSearchTermChange: (search: string) => void
  isInvalidSearch: boolean
  onClear: () => void
  onFocus: () => void
  onSearch: () => void
  onQuickSearch: (key: string) => void
  onSavedFilter: (key: string) => void
  matchingSavedFilter: SavedFilter | undefined
  hasAirlineOrders: boolean
  hasEventTicketingOrders: boolean
}

const SearchContainer: FC<Props> = ({
  onSearchTermChange,
  searchTerm,
  editing,
  isInvalidSearch,
  onClear,
  onFocus,
  onSearch,
  onQuickSearch,
  onSavedFilter,
  matchingSavedFilter,
  hasAirlineOrders,
  hasEventTicketingOrders,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [inputRef, editing])

  const placeholderText = getPlaceholderText(
    hasAirlineOrders,
    hasEventTicketingOrders
  )

  return (
    <>
      <Row onClick={onFocus}>
        <Text
          size="lg"
          data-analytics-id="search-bar"
          className={styles.searchBar}
          data-test-id="searchBar"
        >
          <SearchOutlined
            className={joinClassNames([styles.icon, styles.searchIcon])}
          />

          {editing ? (
            <>
              <input
                data-test-id="searchBarInput"
                className={styles.input}
                placeholder={t(`search.searchBar.${placeholderText}`)}
                value={searchTerm}
                onChange={(e) => {
                  onSearchTermChange(e.target.value)
                }}
                ref={inputRef}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSearch()
                  }
                }}
              />
              {isInvalidSearch && (
                <>
                  <Text data-test-id="invalidSearch" type="danger">
                    {t('search.searchBar.invalidSearch')}
                  </Text>
                  <Space size={18} />
                </>
              )}
              {!!searchTerm && (
                <CloseOutlined
                  data-test-id="clearButton"
                  className={joinClassNames([styles.icon, styles.clearIcon])}
                  onClick={() => onSearchTermChange('')}
                />
              )}
            </>
          ) : (
            <>
              <SearchSummary
                placeholderText={t(`search.searchBar.${placeholderText}`)}
              />
              <ResponsiveIconButton
                buttonClass={styles.clearButton}
                onClick={onClear}
                text={t('search.buttons.clearSearch')}
                Icon={<CloseOutlined />}
              />
            </>
          )}
        </Text>
      </Row>

      {editing && (
        <>
          <Divider className={styles.divider} />

          <div className={styles.quickSearch}>
            <Row>
              <Col xs={{ span: 21 }} lg={{ span: 8 }}>
                <QuickViews onClick={onQuickSearch} />
              </Col>
              <Col xs={{ span: 21 }} lg={{ span: 15 }}>
                <ErrorBoundary
                  message={t('errorBoundaries.savedFilters')}
                  className={styles.errorBoundary}
                >
                  <SavedFilters
                    onClick={onSavedFilter}
                    matchingSavedFilter={matchingSavedFilter}
                  />
                </ErrorBoundary>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  )
}

export default SearchContainer
