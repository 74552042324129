import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { InvestigationInfo } from '@signifyd/http'
import { AdvancedFilterKey } from '../../FiltersContainer/AdvancedFiltersTree'
import SummaryItem from '../SummaryItem'

interface Props<T extends AdvancedFilterKey> {
  filterKey: T
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO FET-1807 come back to this and fix this type
  values: Array<InvestigationInfo[T]>
}

const AdvancedFiltersItem = <T extends AdvancedFilterKey>({
  filterKey,
  values,
}: Props<T>): ReactElement => {
  const { t } = useTranslation()

  return (
    <SummaryItem
      label={t(`filters.advanced.${filterKey}.label`)}
      value={values
        // TODO FET-1807 https://signifyd.atlassian.net/browse/FET-1807
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((item) => t(`filters.advanced.${filterKey}.${item}` as any))
        .join(', ')}
    />
  )
}

export default AdvancedFiltersItem
