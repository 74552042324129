import { Flex } from 'antd'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useStoreActions, useStoreState } from 'store'
import {
  P,
  ErrorBoundary,
  StretchToPageBottom,
  PageSpinnerThirdGen,
  Space,
} from '@signifyd/components'
import NotFound from 'core/components/NotFound'
import styles from './CaseReviewPage.less'
import CaseHeaderNavigationThirdGen from './containers/CaseHeaderNavigationThirdGen'
import CaseHeaderThirdGen from './containers/CaseHeaderThirdGen'
import TabbedCaseDisplay from './containers/TabbedCaseDisplay'

export const CaseReviewPageThirdGen: FC = () => {
  const { t } = useTranslation()
  const { caseId } = useParams()

  const { getCurrentCase, clearCurrentCase } = useStoreActions(
    (actions) => actions.currentCase
  )
  const caseDetails = useStoreState((state) => state.currentCase.details)
  const caseNotFound = useStoreState((state) => state.currentCase.caseNotFound)
  const serverError = useStoreState(
    (state) => state.currentCase.detailsServerError
  )
  const loading = useStoreState((state) => state.currentCase.loading)

  useEffect(() => {
    if (caseId) {
      getCurrentCase(+caseId)
    }

    return () => clearCurrentCase()
  }, [getCurrentCase, clearCurrentCase, caseId])

  return (
    <StretchToPageBottom className={styles.stretchToPageBottom}>
      <Flex vertical className={styles.wrapper}>
        <div className={styles.caseNavigation}>
          <CaseHeaderNavigationThirdGen />
        </div>

        <div className={styles.caseHeader}>
          <ErrorBoundary message={t('errorBoundaries.orderDetails')}>
            <CaseHeaderThirdGen caseDetails={caseDetails} />
          </ErrorBoundary>
        </div>

        <Flex vertical className={styles.caseContent}>
          {(loading || (!serverError && !caseDetails)) && (
            <PageSpinnerThirdGen />
          )}

          {!loading && !serverError && caseNotFound && (
            <NotFound title={t('pages.caseReview.noOrderFound.title')}>
              <P>{t('pages.caseReview.noOrderFound.subTitle')}</P>
            </NotFound>
          )}

          {!loading && serverError && (
            <NotFound title={t('pages.caseReview.errorLoadingOrder.title')}>
              <P>{t('pages.caseReview.errorLoadingOrder.subTitle')}</P>
            </NotFound>
          )}
          {!loading && !caseNotFound && caseDetails && (
            <>
              <Space size="md" />
              <div className={styles.container}>
                <TabbedCaseDisplay />
              </div>
            </>
          )}
        </Flex>
      </Flex>
    </StretchToPageBottom>
  )
}

export default CaseReviewPageThirdGen
