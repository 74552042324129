import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  getCanonicalEventContent,
  getInvestigationAtEvent,
  InvestigationInfo,
  Event,
} from '@signifyd/http'

export interface InvestigationContentData {
  investigation: InvestigationInfo | null
  eventContent: unknown | null
}

const useGetInvestigationAtEvent = (
  event: Event,
  investigationId?: number
): UseQueryResult<InvestigationContentData, AxiosError> => {
  return useQuery<InvestigationContentData, AxiosError>(
    ['investigationAtEvent', investigationId, event.investigationEventId.uuid],
    async () => {
      const {
        investigationEventId: { groupId, typeId, uuid },
      } = event

      const investigationCall = await getInvestigationAtEvent({
        investigationId: investigationId!,
        groupId,
        typeId,
        uuid,
      })

      const eventContentCall = await getCanonicalEventContent({
        investigationId: investigationId!,
        groupId,
        typeId,
        uuid,
      })

      const [
        {
          data: { investigation },
        },
        {
          data: { eventContent },
        },
      ] = await Promise.all([investigationCall, eventContentCall])

      return {
        investigation,
        eventContent,
      }
    },
    {
      enabled: !!investigationId,
    }
  )
}

export default useGetInvestigationAtEvent
