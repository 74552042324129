import { useQuery, UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'
import {
  getInvestigationAtEvent,
  InvestigationInfo,
  Event,
} from '@signifyd/http'

interface InvestigationPairData {
  investigations: Array<InvestigationInfo | null>
  errors: Array<unknown | null>
}

const useGetInvestigationAtEvent = (
  investigationId: number,
  events: Array<Event>
): UseQueryResult<InvestigationPairData> => {
  const [investigation1, investigation2] = events

  return useQuery<InvestigationPairData>(
    [
      'getComparisonEvents',
      `${investigationId}-${investigation1.investigationEventId.uuid}-${investigation2.investigationEventId.uuid}`,
    ],
    async () => {
      const [result1, result2] = await Promise.allSettled([
        getInvestigationAtEvent({
          investigationId,
          groupId: investigation1.investigationEventId.groupId,
          typeId: investigation1.investigationEventId.typeId,
          uuid: investigation1.investigationEventId.uuid,
        }),
        getInvestigationAtEvent({
          investigationId,
          groupId: investigation2.investigationEventId.groupId,
          typeId: investigation2.investigationEventId.typeId,
          uuid: investigation2.investigationEventId.uuid,
        }),
      ])

      return [result1, result2].reduce(
        (acc, result) => {
          if (result.status === 'fulfilled') {
            acc.investigations.push(result.value.data.investigation)
            acc.errors.push(null)
          } else {
            const errorMessage = axios.isAxiosError(result.reason)
              ? result.reason.response?.data
              : 'Something went wrong'

            acc.investigations.push(null)
            acc.errors.push(errorMessage)
          }

          return acc
        },
        { investigations: [], errors: [] } as InvestigationPairData
      )
    },
    {
      enabled: !!investigationId,
    }
  )
}

export default useGetInvestigationAtEvent
