import { Divider, Button, Card, Flex } from 'antd'
import { FC, useState, useEffect, CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useStoreState, useStoreActions } from 'store'
import { ErrorBoundary, useIsThirdGen } from '@signifyd/components'
import { joinClassNames } from '@signifyd/utils'
import { stripDateAndEncode } from 'core/utils/stripDateAndEncode'
import { decodeUrlHash } from 'core/utils/urlEncoder'
import { FilterState, defaultSearchState, SavedFilter } from 'store/search'
import FiltersContainer from './FiltersContainer'
import styles from './SearchAndFilter.less'
import SearchContainer from './SearchContainer'
import ThirdGenSearchContainer from './SearchContainer/ThirdGenSearchContainer/ThirdGenSearchContainer'
import Section from './Section'

type Props = {
  defaultOpen: boolean
  style?: CSSProperties
  handleClose?: () => void
  updateFilters: (filters: FilterState) => void
  filters: FilterState
  updateSearchTerm: (searchTerm: string) => void
  searchTerm: string
  setActiveKey?: (activeKey: string | Array<string>) => void
  hasAirlineOrders?: boolean
  hasEventTicketingOrders?: boolean
}

export const SearchAndFilter: FC<Props> = ({
  defaultOpen,
  style,
  handleClose,
  filters,
  updateFilters,
  searchTerm,
  updateSearchTerm,
  setActiveKey,
  hasAirlineOrders,
  hasEventTicketingOrders,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isThirdGen = useIsThirdGen()

  /*
   * STORE STATE & ACTIONS
   */
  const searching = useStoreState((state) => state.search.searching)
  const getSavedFilterMatch = useStoreState(
    (state) => state.search.getSavedFilterMatch
  )
  const sort = useStoreState((state) => state.search.sort)
  const savedFilters = useStoreState((state) => state.search.savedFilters)

  const { setSearchTerm, searchCases, setFilterValue, setCurrentPage } =
    useStoreActions((actions) => actions.search)

  /*
   * COMPONENT STATE
   */
  const [editing, setEditing] = useState(defaultOpen)
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm)
  const [localFilters, setLocalFilters] = useState(filters)
  const [saveFilterAs, setSaveFilterAs] = useState('')
  const [isInvalidSearch, setIsInvalidSearch] = useState(false)
  const [isInvalidSavedFilter, setIsInvalidSavedFilter] = useState(false)
  const [matchingSavedFilter, setMatchingSavedFilter] = useState<
    SavedFilter | undefined
  >()

  useEffect(() => {
    setLocalSearchTerm(searchTerm)
    setLocalFilters(filters)
  }, [searchTerm, filters])

  useEffect(() => {
    // Check if is saved filters
    const hash = stripDateAndEncode(localSearchTerm, localFilters, sort)
    const foundFilter = Object.values(savedFilters).find(
      (filter) => filter.searchHash === hash
    )

    setMatchingSavedFilter(foundFilter)
    updateSearchTerm(localSearchTerm)
  }, [
    localFilters,
    savedFilters,
    localSearchTerm,
    sort,
    updateFilters,
    updateSearchTerm,
  ])

  /*
   * HANDLERS
   */
  const onSearchTerm = (searchText: string): void => {
    setIsInvalidSearch(
      searchText.split(',').some((term) => term.trim().startsWith('*'))
    )
    setLocalSearchTerm(searchText)
  }

  const onSubmit = (quickSearchKey?: string): void => {
    if (isInvalidSearch || isInvalidSavedFilter) {
      return
    }

    setFilterValue(localFilters)
    setSearchTerm(localSearchTerm.trim())
    setCurrentPage(1)
    searchCases({
      quickSearchKey,
      saveAs: saveFilterAs,
      savedFilterKey: matchingSavedFilter?.title,
      navigate,
    })
    updateFilters(localFilters)
    setSaveFilterAs('')

    if (setActiveKey) {
      setActiveKey([])
    }

    setEditing(false)
  }

  const onFilterChange = (update: Partial<FilterState>): void => {
    setLocalFilters((state) => ({
      ...state,
      ...update,
    }))
  }

  const onClose = (): void => {
    setLocalSearchTerm(searchTerm)
    setLocalFilters(filters)

    setSaveFilterAs('')
    setEditing(false)

    handleClose?.()
  }

  const onFocus = (): void => {
    if (!editing) {
      setEditing(true)
    }
  }

  const onClear = (): void => {
    setLocalFilters(defaultSearchState.filters)
    setLocalSearchTerm('')
    onFocus()
  }

  const onSavedFilter = (savedFilterKey: string): void => {
    const savedFilterMatch = getSavedFilterMatch(savedFilterKey)
    const savedFilter =
      savedFilterMatch.selectedSavedFilter || savedFilterMatch.selectedQuickView
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodedState = decodeUrlHash<any>(savedFilter?.searchHash || '')

    onFilterChange({
      ...decodedState.filters,
    })
    setLocalSearchTerm(decodedState.searchTerm)
  }

  return (
    <div>
      <Card
        className={joinClassNames([styles.wrapper, editing && styles.editing])}
        bordered
        style={style}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        {searching && <div className={styles.searching} />}
        {!isThirdGen && (
          <SearchContainer
            searchTerm={localSearchTerm}
            onSearch={onSubmit}
            onSearchTermChange={onSearchTerm}
            isInvalidSearch={isInvalidSearch}
            editing={editing}
            onClear={onClear}
            onFocus={onFocus}
            onQuickSearch={onSubmit}
            onSavedFilter={onSavedFilter}
            matchingSavedFilter={matchingSavedFilter}
            hasAirlineOrders={!!hasAirlineOrders}
            hasEventTicketingOrders={!!hasEventTicketingOrders}
          />
        )}
        {isThirdGen && (
          <ThirdGenSearchContainer
            searchTerm={localSearchTerm}
            onSearch={onSubmit}
            onSearchTermChange={onSearchTerm}
            isInvalidSearch={isInvalidSearch}
            editing={editing}
            onClear={onClear}
            onFocus={onFocus}
            onQuickSearch={onSubmit}
            onSavedFilter={onSavedFilter}
            matchingSavedFilter={matchingSavedFilter}
            hasAirlineOrders={!!hasAirlineOrders}
          />
        )}

        {editing && (
          <>
            <Divider className={styles.divider} />

            <ErrorBoundary
              message={t('errorBoundaries.searchFilters')}
              className={styles.errorBoundary}
            >
              <FiltersContainer
                filters={localFilters}
                updateFilter={onFilterChange}
                setSaveFilterAs={setSaveFilterAs}
                clearFilters={() => setLocalFilters(defaultSearchState.filters)}
                isInvalidSavedFilter={isInvalidSavedFilter}
                setIsInvalidSavedFilter={setIsInvalidSavedFilter}
              />
            </ErrorBoundary>

            <Section>
              <Flex className={styles.footer} justify="flex-end">
                <Button
                  data-analytics-id="close-filter"
                  data-test-id="search-close-button"
                  type="link"
                  onClick={onClose}
                  size={isThirdGen ? 'large' : 'middle'}
                >
                  {t('search.footer.close')}
                </Button>

                <Button
                  disabled={isInvalidSearch || isInvalidSavedFilter}
                  data-analytics-id="apply-search-button"
                  data-test-id="applySearchButton"
                  type="primary"
                  onClick={() => onSubmit()}
                  loading={searching}
                  size={isThirdGen ? 'large' : 'middle'}
                >
                  {t(`search.footer.${searching ? 'applying' : 'apply'}`)}
                </Button>
              </Flex>
            </Section>
          </>
        )}
      </Card>
    </div>
  )
}

export default SearchAndFilter
